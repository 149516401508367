export const initialState = {
  location: null,
  isOneTrustInitialized: false,
  locale: "en",
  isCartInitialized: false,
  cart: null,
  accountId: null,
  activeAccount: null,
  accounts: [],
  currency: null,
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOCATION":
      return {
        ...state,
        location: action.payload,
      }
    case "ONE_TRUST_INITIALIZED":
      return {
        ...state,
        isOneTrustInitialized: true,
      }
    case "SET_LOCALE":
      return {
        ...state,
        locale: action.payload.locale,
      }
    case "SET_CART_INITIALIZED":
      return {
        ...state,
        isCartInitialized: true,
      }
    case "UPDATE_CART":
      return {
        ...state,
        cart: action.payload,
      }
    case "SET_ACCOUNTS":
      return {
        ...state,
        accounts: action.payload,
      }
    case "PATCH_ACCOUNTS":
      const newAccounts = state?.accounts.map(account => {
        if (action.payload.id === account.id) {
          return action.payload
        }
        return account
      })      
      return {
        ...state,
        accounts: newAccounts,
      }      
    case "SET_ACCOUNT_ID":
      return {
        ...state,
        accountId: action.payload,
      }
    case "SET_ACTIVE_ACCOUNT":
      return {
        ...state,
        activeAccount: action.payload,
      }
    case "SET_CURRENCY":
      return {
        ...state,
        currency: action.payload,
      }      
    default:
      return state
  }
}
