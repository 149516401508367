import React from "react"
import { GlobalAuthProvider } from "./src/context/auth/auth"
import { initialState, authReducer } from "./src/context/auth/authReducer"
import { loadConfig } from "./src/utils/config/config"
const overrideConfig =
  process.env.GATSBY_CONFIG_OVERRIDE && JSON.parse(process.env.GATSBY_CONFIG_OVERRIDE)

export function onClientEntry() {
  const paramsString = window.location.search;
  const searchParams = new URLSearchParams(paramsString);

  for (const [key, value] of searchParams) {
    if (key.startsWith('utm_')) {
      sessionStorage.setItem(key, value.trim());
    }
  }
}

export function onRouteUpdate({location}) {
  const paramsString = window.location.search;
  const searchParams = new URLSearchParams(paramsString);

  let hasUTMSaved = false
  const keys = [
    'utm_source', 
    'utm_medium', 
    'utm_campaign', 
    'utm_content', 
    'utm_term', 
    'utm_partner',
  ]
  
  keys.forEach(key => {
    const value = sessionStorage.getItem(key);
    if (value) {
      hasUTMSaved = true
      searchParams.set(key, value)
    }
  })

  if (hasUTMSaved) {
    window.history.replaceState(
      location.state, 
      "", 
      `${location.pathname}?${searchParams.toString()}`
    );
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <GlobalAuthProvider initialState={initialState} reducer={authReducer}>
      {element}
    </GlobalAuthProvider>
  )
}
